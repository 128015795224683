import { Link } from 'react-router-dom'
import { ServiceCardProps } from '../../../models/ourServices'
import save from '../../../assets/pics/save.svg'
import './serviceCard.css'

function ServiceCard(props: ServiceCardProps) {
  const {buttonContent, image, to, title = 'new service', des = 'New service',  price = props.price ?? -1 ,coupon = props.coupon ?? null} = props;
  
  return (
    <div className='serviceCard'>
        <div className='serviceImWra'>
          {/*<button className='saveButton'>
            <img src={save} alt='save' className='save'/>
          </button>*/}
          <img src={image} alt='pic' className='serviceImage'/>
          <Link to={to} className='whiteLink'>{buttonContent}</Link> 
        </div>
        <div>
          <span className="secondaryTitle">{title}</span>
          <p className='mt-3'>{des}</p>
          <br />
          {price !== -1 && (
            <p className={`servicePrice ${coupon ? "oldPrice" : ""}`}>${price}</p>
          )}
          <br />
          {/* <div className="servicePricing"> */}
          {coupon !== null && (
            <div className="couponTitle">
              <p className="couponName">{coupon.name}</p>
              <p className="couponDiscount">-{coupon.discount}% Off</p>
              <p className="newPrice">
                <span>${(price - (price * coupon.discount) / 100).toFixed(2)}</span>
              </p>
            </div>
          )}
          {/* </div> */}
        </div>
    </div>
  )
}

export default ServiceCard