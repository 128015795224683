import ServiceCard from '../../components/sharedComponents/serviceCard/ServiceCard'
import blueStar from '../../assets/pics/ourServices/blueStar.svg'
import doctor from '../../assets/pics/ourServices/doctor.svg'
import group from '../../assets/pics/ourServices/group.svg'
import './ourServices.css'
import { fetchData } from '../../api/crudServices'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getLang } from '../../utils/functions/authCheck'
import lang from '../../utils/lang/lang'

function OurServices() {
    const[department, setDepartment] = useState<any>()
    const {id} = useParams(), currentLnag = getLang(), {ourServices} = lang[currentLnag]

    useEffect(()=>{
      async function fetchDepartments(){
        try{
          const res = await fetchData(`user/department/get_one/${id}`)
          setDepartment(res.data)
        }catch(error){
          console.error(error)
        }
      }
      fetchDepartments()
    }, [])
    if(!department)
      return <></>

  return (
    <div className='ourServices'>
        <div className='paper deaprtment'>
          <img src={department.image} alt="deaprtment" />
          <div className="departmentDes">
            <h2 className="secondaryTitle">{department.name}</h2>
            <p>{department.description}</p>
            <div className='numbers'>
              <div>
                <img src={group} alt="ic" />
                <span>+{department.completed_appointments_count} {ourServices.info[0]}</span>
              </div>
              <div>
                <img src={doctor} alt="ic" />
                <span>+{department.doctor_count} {ourServices.info[1]}</span>
              </div>
              <div>
                <img src={blueStar} alt="ic" />
                <span>{department.average_rating ? `+${department?.average_rating} ${ourServices.info[2]}` : ourServices.info[3]}</span>
              </div>
            </div>
          </div>
        </div>
        <h2 className='header2'>{ourServices.title}</h2>
        <p className='secIntro'>{ourServices.smallDescription}</p>
        <div className='cardsWra text-start'>
        
          {
              department?.services.map((ser:any) => <ServiceCard key={ser.id} title={ser.name} coupon={ser.coupon} price={ser.price} des={ser.description} buttonContent={ourServices.button} image={ser.image} to={`/ourservices/service/${ser.id}`}/>)
          }
        </div>
    </div>
  )
}

export default OurServices