import AppBarPC from '../appBar/AppBarPC'
import AppBarMo from '../appBar/AppBarMo'
import { Outlet } from 'react-router-dom'
import ScrollToTop from '../../../utils/functions/ScrollToTop'
import { useAppDispatch} from '../../../RTK/hooks/hooks'
import { getProfileRequest } from '../../../RTK/features/userSlice/userSlice'
import { useEffect } from 'react'
import { authCheck, getLang } from '../../../utils/functions/authCheck'

function Layout() {
  const dispatch = useAppDispatch()
  useEffect(() => {
    if(authCheck())
      dispatch(getProfileRequest());
  }, []);

  return (
    <div dir={getLang() === 'en' ? 'ltr' : 'rtl'} className='rtl:font-rubik'>
        <AppBarPC/>
        <AppBarMo/>
        <ScrollToTop/>
        <Outlet/>
    </div>
  )
}

export default Layout