const lang = {
  en: {
    navigation: ["Homepage", "Blog", "Apply for a job"],
    home: {
      companyIntro: {
        button: "Our Deparments",
        text1: "With us",
        text2: "you are safe",
        text3: "so don't worry",
      },
      ourVision: {
        title: "Your health is safe in our hands",
        smallDescription: "Driving innovation for a better future.",
        label: "Our Vision",
        sec1: {
          title: "At Elegance Hub,",
          des: " we redefine self-care as a journey where beauty, wellness, and confidence converge. Under one roof, we seamlessly blend advanced dermatology, precision dentistry, aesthetic innovations, and premier medical care, ensuring every touchpoint is guided by expertise, artistry, and compassion",
        },
        sec2: {
          title: "More than",
          des: " just a destination for treatments, Elegance Hub is an experience—where appointments are effortless, care is personal, and transformation is inevitable. Our curated online store offers handpicked skincare and wellness essentials, while our insightful blog empowers you with expert advice, trends, and self-care wisdom Beauty and healthcare aren’t just seen—it’s felt, experienced, and lived. And at Elegance Hub, we make every step of your journey effortless, radiant, and uniquely yours.",
        },
        button: "Our Departments",
      },
      ourDepartments: {
        title: "Your life our responsibility",
        smallDescription: "Driving innovation for a better future.",
        label: "Our Departments",
        button: "Check it out",
      },
      topProviders: {
        title: "Some of our best providers",
        smallDescription: "our providers",
        label: "Top Providers",
      },
      ourBlogs: {
        title: "Latest health and medical news",
        smallDescription: "Read latast health news",
        label: "Blogs",
        button: "Read more",
      },
      someRatings: {
        title: "Some of our customers' reviews",
        smallDescription: "Our Customer",
        label: "Ratings",
      },
    },
    applyForAJob: {
      title: "select the section you want to work in",
      smallDescription: "new job for you",
      label: "Apply for a job",
    },
    jobForm: {
      label: "Application Form",
      description: [
        "Department",
        "Location",
        "Job duties",
        "Education",
        "Knowledge, skills and abilities",
        "Experience",
      ],
      form: ["Name", "Phone", "Email", "Gender", "Description", "Your CV"],
      fileInput: "Click or drag the file here to exoprt it",
      button: "Submit",
    },
    articles: {
      title1: "Most important blogs",
      smallDescription1: "Most important",
      title2: "Latest news",
      smallDescription2: "Latest",
      title3: "Top Writers",
      smallDescription3: "Our top writers",
      button: "Read More",
    },
    ourServices: {
      title: "Services of the section",
      smallDescription: "Our services",
      button: "Check it out",
      info: ["Appointments", "Professional doctors", "stars", "Not rated yet"],
    },
    bookings: {
      addAService: "Book a service",
      chooseADoctor: "Choose your doctor",
      chooseDate: "Choose date and time",
      checkInfo: "Date and time",
      pay: "Choose your payment method",
      cancel: "Cancel",
      confirm: "Confirm",
      next: "Next",
    },
    profile: {
      personCard: ["Blood type:", "Last medical check:"],
      appointments: {
        navigation: {
          title: "See your bookings here",
          smallDescription: "your bookings",
          label: "Your bookings",
          navigation: ["On queue", "Completed", "Cancelled"],
          completed: {
            submit: "Submit",
            rate: "Rate your appointment",
            comment: "Leave a comment",
            placeholder: "Your comment..",
          },
        },
        buttons: ["Change Date", "Cancel", "Rate your appointment"],
      },
      form: {
        title: "Got a complaint?",
        coloredTitle: "Tell Us",
        formPlaceholders: [
          "Full name",
          "Your email",
          "Phone number",
          "Write your complaint",
        ],
        button: "Send your complaint",
      },
    },
  },
  ar: {
    navigation: ["الصفحة الرئيسية", "المدونة", "التقديم على وظيفة"],
    home: {
      companyIntro: {
        button: "أقسامنا",
        text1: "معنا",
        text2: "أنت بأمان",
        text3: "لذا لا تقلق",
      },
      ourVision: {
        title: "صحتك بأيدٍ أمينة معنا",
        smallDescription: "قيادة الابتكار من أجل مستقبل أفضل",
        label: "رؤيتنا",
        sec1: {
          title: "في مركز الإليجانس،",
          des: "نعيد تعريف العناية الذاتية كرحلة تلتقي فيها الجمال والرفاهية والثقة. تحت سقف واحد، ندمج بسلاسة بين طب الجلد المتقدم، طب الأسنان الدقيق، الابتكارات الجمالية، والرعاية الطبية المتميزة، مع ضمان توجيه كل خطوة بالخبرة والفن والرحمة",
        },
        sec2: {
          title: "أكثر من",
          des: "مجرد وجهة للعلاجات، مركز الإليجانس هو تجربة - حيث تكون المواعيد سهلة، والرعاية شخصية، والتحول حتمي. متجرنا الإلكتروني المختار يقدم مستلزمات العناية بالبشرة والرفاهية المختارة بعناية، بينما تمنحك مدونتنا الثاقبة نصائح الخبراء، الاتجاهات، وحكمة العناية الذاتية. الجمال والرعاية الصحية لا يُرى فحسب - بل يُشعر به ويُعاش. وفي مركز الإليجانس، نجعل كل خطوة في رحلتك سهلة، مشرقة، وفريدة من نوعك",
        },
        button: "أقسامنا",
      },
      ourDepartments: {
        title: "حياتك مسؤوليتنا",
        smallDescription: "قيادة الابتكار من أجل مستقبل أفضل",
        label: "أقسامنا",
        button: "تفقد الخدمة",
      },
      topProviders: {
        title: "بعض من أفضل مقدمي الخدمات لدينا",
        smallDescription: "مقدمي خدماتنا المتميزين",
        label: "أفضل المزودين",
      },
      ourBlogs: {
        title: "آخر أخبار الصحة والطب",
        smallDescription: "اقرأ آخر أخبار الصحة",
        label: "المدونة",
        button: "اقرأ المزيد",
      },
      someRatings: {
        title: "بعض تقييمات عملائنا",
        smallDescription: "عملاؤنا الكرام",
        label: "التقييمات",
      },
    },
    applyForAJob: {
      title: "اختر القسم الذي ترغب العمل به",
      smallDescription: "وظيفة جديدة تنتظرك",
      label: "التقديم على وظيفة",
    },
    jobForm: {
      label: "نموذج التقديم",
      description: [
        "القسم",
        "الموقع",
        "مهام العمل",
        "المؤهل العلمي",
        "المهارات والقدرات",
        "الخبرة",
      ],
      form: [
        "الاسم",
        "الهاتف",
        "البريد الإلكتروني",
        "الجنس",
        "الوصف",
        "السيرة الذاتية",
      ],
      fileInput: "انقر أو اسحب الملف هنا لرفعه",
      button: "إرسال",
    },
    articles: {
      title1: "المقالات الأكثر أهمية",
      smallDescription1: "المقالات الأكثر أهمية",
      title2: "آخر الأخبار",
      smallDescription2: "آخر الأخبار",
      title3: "أفضل الكتّاب",
      smallDescription3: "أفضل كتابنا",
      button: "اقرأ المزيد",
    },
    ourServices: {
      title: "خدمات القسم",
      smallDescription: "خدماتنا المميزة",
      button: "تفقد الخدمة",
      info: ["المواعيد", "أطباء محترفون", "النجوم", "لم يتم التقييم بعد"],
    },
    bookings: {
      addAService: "حجز خدمة",
      chooseADoctor: "اختر طبيبك",
      chooseDate: "اختر التاريخ والوقت",
      checkInfo: "التاريخ والوقت",
      pay: "اختر طريقة الدفع",
      cancel: "إلغاء",
      confirm: "تأكيد",
      next: "التالي",
    },
    profile: {
      personCard: ["فصيلة الدم:", "آخر فحص طبي:"],
      appointments: {
        navigation: {
          title: "تفقد حجوزاتك هنا",
          smallDescription: "حجوزاتك",
          label: "حجوزاتك",
          navigation: ["في الانتظار", "مكتمل", "ملغى"],
          completed: {
            submit: "إرسال",
            rate: "قيم موعدك",
            comment: "اترك تعليقًا",
            placeholder: "تعليقك...",
          },
        },
        buttons: ["تغيير الموعد", "إلغاء", "قيم موعدك"],
      },
      form: {
        title: "هل لديك شكوى؟",
        coloredTitle: "أخبرنا بها",
        formPlaceholders: [
          "الاسم الكامل",
          "بريدك الإلكتروني",
          "رقم الهاتف",
          "اكتب شكواك هنا",
        ],
        button: "أرسل شكواك",
      },
    },
  },
};
  
  export default lang;
  