import './checkInfo.css'
import profilePic from '../../../../assets/pics/profilePic.webp'
import clock from'../../../../assets/pics/bookAService/clock.svg'
import calendar from '../../../../assets/pics/calendar.svg'
import { CheckInfoProps } from '../../../../models/bookAService'
import { useEffect, useState } from 'react'
import { fetchData, postData } from '../../../../api/crudServices'
import { formatTimes } from '../../../../utils/functions/categorizeTimes'
import toast from 'react-hot-toast'
import { time } from 'console'
import lang from '../../../../utils/lang/lang'
import { getLang } from '../../../../utils/functions/authCheck'

/*
In this step, you should've saved the info about the doctor in json string format
in the appointment state, here you take that info with day and time
show everything for the user so they can confirm the request
*/


function CheckInfo(props: CheckInfoProps) {
  const [doctorInfo, setDoctorInfo] = useState({name: '', email: '', image: ''})
  const {setStep, appointment, handleEnd, update} = props
  const currentLang = getLang(), {bookings} = lang[currentLang]

  useEffect(()=>{
    async function fetchDoctor(){
      const res = await fetchData(`user/doctor/showProfile/${appointment.doctor}`)
      setDoctorInfo(res.data)
    }
    fetchDoctor()
  },[])
  return (
    <div className='checkInfo step hideScrollBar'>
      <img src={profilePic} alt='doctorPic'/>
      <div className='checkInfoDoctor'>
        <h4 className='importantText'>{doctorInfo.name}</h4>
        <p>{doctorInfo.email}</p>
      </div>
      <div className='checkInfoDate'>
        <h4 className='importantText'>{bookings.checkInfo}</h4>
        <div className='dateDets'>
          <img src={calendar} alt="date" className='iconWithBlueBg'/>
          <span>{appointment.day}</span>
        </div>
        <div className='dateDets'>
          <img src={clock} alt="time" className='iconWithBlueBg'/>
          <span>{formatTimes([appointment.time])[0]}</span>
        </div>
      </div>
      <div className='checkInfoButtons'>
        <button onClick={()=>handleEnd()} className='cancel'>{bookings.cancel}</button>        
        <button 
          onClick={async ()=>{
            try{
              if(update){
                await postData(`user/appointment/update/${update}`, {date: appointment.day, time:appointment.time})
                toast.success('Your appointment has been updated')
              }else{
              const res = await postData('user/appointment/store', {date: appointment.day, time: appointment.time, doctor_id: appointment.doctor})
              appointment.id = res.data.id ;
              toast.success('Your appointment has been registered')
              }
            } catch (err: any) {
              console.error(err);
              toast.error(err?.response?.data?.error as string);
            } finally{
              setStep(pre => pre+1)}
          }} 
          className='confirm'>{bookings.confirm}
        </button>        
      </div>
    </div>
  )
}

export default CheckInfo