import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import './checkout.css';
import { CheckoutProps} from '../../../../models/bookAService';
import { postData } from '../../../../api/crudServices';
import toast from 'react-hot-toast';
import { getLang } from '../../../../utils/functions/authCheck';

function Checkout(props: CheckoutProps) {
    const {appointment_id, open, setOpen, handleEnd } = props;
    let stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY|| '');
    const currentLang = getLang();

    if (!open) return <></>;

    return (
        <div className="modalBackdrop" onClick={() => setOpen(false)}>
            <div className="modal" onClick={(e) => e.stopPropagation()}>
                <h2 className="importantText">{currentLang === 'en' ? 'Checkout Forms' : 'استبيان الدفع'}</h2>
                <Elements stripe={stripePromise}>
                    <CheckoutForm appointment_id={appointment_id} handleEnd={handleEnd} setOpen={setOpen} />
                </Elements>
            </div>
        </div>
    );
}

function CheckoutForm({ appointment_id, handleEnd, setOpen }:{appointment_id: number, handleEnd: ()=>void, setOpen: React.Dispatch<React.SetStateAction<boolean>> }) {
    const stripe = useStripe();
    const elements = useElements();
    const [isLoading, setIsLoading] = useState(false);
    const currentLang = getLang();

    async function handleSubmit(e: React.FormEvent) {
        e.preventDefault();

        if (!stripe || !elements) return;
        setIsLoading(true);
        const cardElement = elements.getElement(CardElement);

        if (!cardElement) {
            console.error('CardElement not found');
            toast.error("We couldn't find CardElement");
            setIsLoading(false);
            return;
        }

        const { error, token } = await stripe.createToken(cardElement);
        console.log(token)
        if (error) {
            console.error(error.message);
            toast.error(error.message || 'An error occurred during token creation');
            setIsLoading(false);
            return;
        }

        try {
            console.log(appointment_id);
            await postData('user/appointment/payment', { stripeToken: token?.id ,appointment_id:appointment_id });
            toast.success('Your payment is complete');
            setOpen(false);
            handleEnd();
        } catch (err: any) {
            console.error(err);
            toast.error(err?.response?.data?.message as string);
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <form onSubmit={handleSubmit} className="checkoutForm">
            <CardElement className="stripeElement rtl:text-right" />
            <button
                type="submit"
                disabled={!stripe || isLoading}
            >
                {isLoading
                    ? currentLang === 'en' ? 'Processing...' : 'جار التحقق...'
                    : currentLang === 'en' ? 'PAY' : 'ادفع'}
            </button>
        </form>
    );
}

export default Checkout;
